/* EmailForm.module.css */
.email-getter * {
  padding: 0;
  margin: 0;
}

.email-getter {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
}

.email-getter form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 45px;
  margin: 50px 0px;
}

.email-getter h2 {
  text-align: center;
  color: white;
  font-size: clamp(24px, 2vw, 32px);
  font-weight: bold;
  font-family: 'Poppins', sans-serif;
  margin: 0;
}

.email-getter h3 {
  font-size: clamp(16px, 2.5vw, 20px);
  font-weight: 100;
  font-family: 'Poppins', sans-serif;
  color: white;
  max-width: 500px;
  text-align: center;
}

.email-getter #email {
  width: 100%;
  max-width: 400px;
  box-sizing: border-box;
  height: 40px;
  border-radius: 45px;
  padding-left: 25px;
  border: 2px white solid;
  background-color: rgba(147, 69, 208, 0.3);
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.7);
  outline: none;
}

.email-getter #email:focus {
  border-color: #9345d0;
}

.email-getter #email::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.email-getter #email:-webkit-autofill {
  border: 3px solid #9345d0;
}

.email-getter button {
  width: 200px;
  height: 45px;
  border-radius: 30px;
  color: white;
  background-color: #9345d0;
  border: none;
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
}

.email-getter button:hover {
  cursor: pointer;
  background-color: #b258f7;
}

.email-getter .info {
  color: white;
}

.email-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.info {
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  text-align: center;
}

.info.valid {
  color: #00E99B;
}

.info.invalid {
  color: #D04545;
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 15px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: l5 1s infinite linear alternate;
}

@keyframes l5 {
    0%  {box-shadow: 20px 0 #9345d0, -20px 0 rgba(147, 69, 208, 0.2); background: #9345d0}
    33% {box-shadow: 20px 0 #9345d0, -20px 0 rgba(147, 69, 208, 0.2); background: rgba(147, 69, 208, 0.2)}
    66% {box-shadow: 20px 0 rgba(147, 69, 208, 0.2), -20px 0 #9345d0; background: rgba(147, 69, 208, 0.2)}
    100%{box-shadow: 20px 0 rgba(147, 69, 208, 0.2), -20px 0 #9345d0; background: #9345d0}
}

@media (max-width: 1035px) {
  .email-getter button {
      font-weight: 600;
      width: 150px;
  }

  .email-getter #email {
    width: 85%;
  }
}

