.header {
    background-color: #000000;
    color: white;
    padding: 14px 0px 21px 25px;
    box-sizing: border-box;
    height: 80px;
    display: flex;  /* Utilisation de flexbox */
    align-items: center;  /* Centre verticalement les éléments enfants */
    height: 100px;
}

.header img {
    width: 11%;  /* Largeur du logo */
    min-width: 100px;
    height: auto;  /* Maintenir les proportions */
    max-height: 75px;  /* Hauteur maximale */
    display: block;
}

@media (max-width: 1035px) {
    .header {
        padding: 21px;
        height: 70px;
    }
  }
