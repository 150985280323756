.footer * {
  padding: 0;
  margin: 0;
}

.footer {
    background-color: #000000;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 0px 25px 0px;
}

.footer ul {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 25px;
  flex-wrap: wrap;
  margin: 35px 0px;
  font-size: 16px;
  font-family: 'Monserrat', sans-serif;
  font-weight: 100;
}

.footer ul a {
  text-decoration: none;
  color: white;
}

.footer p {
  margin: 0;
}

.footer img {
  width: 11%;  /* Largeur du logo */
  min-width: 125px;
  height: auto;  /* Maintenir les proportions */
  max-height: 75px;  /* Hauteur maximale */
  display: block;
}