* {
    user-select:none;
}

.HomePage {
    font-family: Arial, sans-serif;
}

main {
    padding: 50px 30px;
    display: flex;
    justify-content: space-around;
    background-color: #0F0F0F;
    flex-wrap: wrap;
}

@media (max-width: 1035px) {
    main {
        flex-direction: column-reverse;
    }
}
  