/* src/styles/Popup.css */
.info-popup * {
  padding: 0;
  margin: 0;
}
.info-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, rgba(255, 255, 255, 0) 0%, rgba(147, 69, 208, 0.67) 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.info-popup-content {
  width: 60%;
  max-width: 500px;
  background: black;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  border-radius: 28px;
  gap: 35px;
}

.info-popup-content h2 {
  max-width: 90%;
  text-align: center;
  font-weight: bolder;
  font-family: 'Poppins', sans-serif;
  font-size: 32px;
}

.info-popup button {
  width: 200px;
  height: 45px;
  border-radius: 30px;
  color: white;
  background-color: #9345d0;
  border: none;
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
}

.info-popup button:hover {
  cursor: pointer;
  background-color: #b258f7;
}

.info-popup p {
  font-family: 'Popins', sans-serif;
  max-width: 90%;
  text-align: center;
  font-size: 20px;
}





