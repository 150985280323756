.product-presentation * {
  margin: 0;
  padding: 0;

}


.product-presentation {
  text-align: center;
  flex: 1;
  margin: 25px 0px;
  width: 100%;
}

.product-presentation h2 {
  color: white;
  font-size: clamp(24px, 2vw, 32px);
  font-family: 'Montserrat', sans-serif;
  font-weight: bolder;
  margin-bottom: 45px;
  line-height: 50px;
}

.product-presentation span {
  padding-bottom:10px;
  border-bottom: 3px solid #9345D0;
}

.product-presentation h3 {
  padding-top: 20px;
  font-size: clamp(16px, 2.5vw, 20px);
  font-weight: 100;
  font-family: 'Poppins', sans-serif;
  max-width: 500px;
  color: white;
  margin: auto;
}

.product-presentation img {
  width: 50%;
  max-width: 233px;
}
  