.head-banner {
    height: 183px;
    background: linear-gradient(to bottom, #9345D0, #682AF0);
    display: flex; /* Use flexbox */
    justify-content: center; /* Horizontally center content */
    align-items: center;
}
.head-banner h1 {
    font-size: clamp(38px, 5vw, 64px);
    font-weight: bold;
    color: white;
    font-family: 'Poppins', sans-serif;
}


@media (max-width: 1035px) {
    .head-banner {
        height: 150px;
    }
  }


